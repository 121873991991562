import axios from 'axios';
import paths from '../paths';

export default function dispatchPresets(dbAuthor) {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_PRESETS',
      payload: axios.get(`${paths.API_CONFIGURATOR}/presets/author/${dbAuthor}`),
    });
  };
}
