import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  RGButton,
  RGButtonEnums,
  RGFormContainer,
  RGInputField,
} from 'react-rg-designsystem';

import '../style/PriceDataDownload.scss';
import downloadOrdersTable from '../data/priceData';

export default function PriceDataDownload() {
  const { dbAuthor } = useParams();
  const [value, setValue] = useState('');

  // Handle events
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const handleSubmit = (newValue) => {
    // Remove all spaces
    const finalValue = newValue;
    finalValue.replace(/\s/g, '');

    // Apply to state
    setValue(finalValue);

    // Download ordersTable
    downloadOrdersTable(dbAuthor, finalValue);
  };
  const handleClick = () => {
    handleSubmit(value);
  };

  // Render
  return (
    <div
      className="PriceDataDownload"
    >
      <RGFormContainer className="priceDataDownload">
        <h4>Preislisten / Bestellungen</h4>
        <RGInputField
          callToAction="ABC123,DEF456,GHI789"
          value={value}
          onChange={handleChange}
          onSubmit={handleSubmit}
        />
        <div className="alignRight">
          <RGButton
            label="herunterladen"
            emphasis={RGButtonEnums.emphasis.high}
            onClick={handleClick}
          />
        </div>
      </RGFormContainer>
    </div>
  );
}
