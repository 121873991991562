/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';

import {
  RGPopup,
  RGButton,
  RGButtonEnums,
  RGInputField,
} from 'react-rg-designsystem';

import useLocalization from '../hooks/useLocalization';
import useSave from '../hooks/useSave';
import AccessCode from './AccessCode';

import '../style/SaveScreen.scss';

library.add(faCheckCircle);

export default function SaveScreen({
  onClose,
}) {
  const translate = useLocalization();
  const { isSaved, save, activeConfiguration } = useSave(true);

  // const [typedMail, setTypedMail] = React.useState('');

  // Save when not saved
  useEffect(() => {
    if (!isSaved()) {
      save();
    }
  }, []);

  // Render
  return (
    <RGPopup
      className="SaveScreen"
      // icon={faCheckCircle}
      title={translate('save.title')}
      description={translate('save.description')}
      onClose={onClose}
    >
      <AccessCode
        code={activeConfiguration.data.code}
        translate={translate}
      />
      {/* <div className="separator slim high-emph" />
      <h4>{translate('save.mail.title')}</h4>
      <p>{translate('save.mail.description')}</p>
      <RGInputField
        placeholder={translate('save.mail.title')}
        onChange={setTypedMail}
      />
      <RGButton
        label={translate('send')}
        onClick={() => { save(typedMail); }}
        emphasis={RGButtonEnums.emphasis.high}
        size={RGButtonEnums.size.large}
      /> */}
    </RGPopup>
  );
}
