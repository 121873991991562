/// Load state from local stroage
/// @param {string} key - Name of the local storage entry
/// @param {object} state - The state that will be modified. The loaded data will be added.
/// @param {object} defaultState - Default object of the data entry
/// @param {string} id - Sub key to use for the local storage entry
///                      If included, only the sub key will be returned in the data
export function loadStateFromStorage(key, state, defaultValue, id = undefined) {
  let newState = { ...state };

  // Get local data
  const localDataString = localStorage.getItem(key);

  // Missing?
  if (localDataString === null || localDataString === 0) {
    newState.data = defaultValue;
    return newState;
  }

  // Load
  newState.data = JSON.parse(localDataString);

  // ID?
  if (id !== undefined) {
    const mappedState = {
      ...state,
      data: newState.data[id],
    };
    newState = mappedState;
  }

  return newState;
}

/// Save state to local storage
/// @param {string} key - Name of the local storage entry
/// @param {object} state - State of a single object to save
/// @param {string} id - Optional ID of the object to save to a sub-entry
export function saveStateToStorage(key, state, id = undefined) {
  let newData = {};
  if (id === undefined) {
    newData = state;
  } else {
    newData[id] = state.data;
  }
  localStorage.setItem(
    key,
    JSON.stringify(newData),
  );
}

export function clearStorage(key, id = undefined) {
  if (!key) {
    return;
  }

  if (id === undefined) {
    localStorage.removeItem(key);
  } else {
    const localDataString = localStorage.getItem(key);
    if (localDataString) {
      const localData = JSON.parse(localDataString);
      if (localData[id] !== undefined) {
        delete localData[id];
        localStorage.setItem(
          key,
          JSON.stringify(localData),
        );
      }
    }
  }
}

export function combineIds(ids) {
  return ids.join('.');
}
