import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';

import {
  RGPopup,
  RGButton,
  RGButtonEnums,
  RGInputField,
} from 'react-rg-designsystem';

import useLocalization from '../hooks/useLocalization';

import '../style/OpenCodeScreen.scss';

library.add(faCheckCircle);

export default function OpenCodeScreen({
  onClose,
  onConfirm,
}) {
  const translate = useLocalization();
  const [typedCode, setTypedCode] = useState('');

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  // Event handlers
  const handleConfirm = () => {
    setSearchParams({ code: typedCode });

    if (onConfirm) {
      onConfirm(typedCode);
    }
  };

  // Render
  return (
    <RGPopup
      className="OpenCodeScreen"
      title={translate('onboarding.openCode.title')}
      description={translate('onboarding.openCode.description')}
      onClose={onClose}
    >
      <RGInputField
        callToAction={translate('onboarding.openCode.placeholder')}
        isRequired
        onChange={(value) => setTypedCode(value)}
      />
      <RGButton
        label={translate('onboarding.openCode.button.minimal')}
        onClick={handleConfirm}
        emphasis={RGButtonEnums.emphasis.high}
        size={RGButtonEnums.size.large}
      />
    </RGPopup>
  );
}
