import React from 'react';

import '../../style/components/FormLayout.scss';

export interface RGFormContainerProps {
  className?: string,
  children: any;
}

export default function RGFormContainer(props: RGFormContainerProps) {
  return (
    <div
      className={`RGFormContainer ${props.className}`}
    >
      {props.children}
    </div>
  );
}
