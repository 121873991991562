import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from './routes/Home';
import Viewer from './routes/Viewer';
import Editor from './routes/Editor';
import Contract from './routes/Contract';
import './style/App.scss';

function App() {
  return (
    <div className="App">
      {/* <BaseLayout /> */}
      <Routes>
        <Route
          exact
          path=""
          element={<Home />}
        />
        <Route
          exact
          path="/view/:dbAuthor/:configuratorId"
          element={<Viewer />}
        />
        <Route
          exact
          path="/edit/:dbAuthor"
          element={<Editor />}
        />
        <Route
          exact
          path="/contract/:dbAuthor/:configuratorId"
          element={<Contract />}
        />
      </Routes>
    </div>
  );
}

export default App;
