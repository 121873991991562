// import { useDispatch } from 'react-redux';
import {
  useParams,
  useSearchParams,
} from 'react-router-dom';

// import { dispatchNewConfiguration } from '../data/actions/dispatchConfiguration';
import { clearStorage } from '../data/localStorage';

export default function useRestart() {
  const {
    // dbAuthor,
    configuratorId,
  } = useParams();
  // const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  // Functions
  const restart = () => {
    clearStorage('activeStages', configuratorId);
    clearStorage('activeConfigurations', configuratorId);

    // Reset the code search parameter
    searchParams.set('code', undefined);
    setSearchParams(searchParams);

    // Reload the page without losing the url parameters
    window.location.reload(true);
  };

  // Return
  return { restart };
}
