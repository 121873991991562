import React from 'react';

import {
  RGBrandLogo,
  RGSidebarLeftList,
} from 'react-rg-designsystem';

import { useBrandImages } from '../hooks/useBrand';

import '../style/BrandToolbarDesktop.scss';

export default function BrandToolbarDesktop({
  className,
  children,
}) {
  const {
    alt,
    logoOnBrightSurface,
  } = useBrandImages();

  // Render
  return (
    <div
      className={`BrandToolbarDesktop desktop ${className || ''}`}
    >
      <RGSidebarLeftList>
        <RGBrandLogo
          src={logoOnBrightSurface}
          alt={alt}
        />
        { children }
      </RGSidebarLeftList>
    </div>
  );
}
