import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../style/components/ContainerButton.scss';

export interface RGContainerButtonProps {
  icon: any;
  onClick: (e : any) => void;
  className?: string;
}

export default function RGContainerButton(props: RGContainerButtonProps) {
  return (
    <button
      className={`RGContainerButton ${props.className || ''}`}
      type="button"
      onClick={props.onClick}
    >
      <FontAwesomeIcon
        icon={props.icon}
      />
    </button>
  );
}
