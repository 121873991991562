import { useSelector } from 'react-redux';

import translations, { defaultLanguage } from '../data/localization';

export default function useLocalization() {
  const language = useSelector((state) => state.localization.language);

  return (key) => {
    if (!translations[key]) {
      console.error(`Localization can't find the key: ${key}`);
      return '';
    }
    return translations[key][language]
      ? translations[key][language]
      : translations[key][defaultLanguage];
  };
}
