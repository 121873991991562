import React, { useState, useRef } from 'react';

import { useSlimSize } from '../hooks/useWindowsResize';
import Cart from './Cart';
import ConfigurationActions from './ConfigurationActions';
import SaveScreen from './SaveScreen';
import RestartScreen from './RestartScreen';

import '../style/ConfigurationOverview.scss';

export default function ConfigurationOverview({
  dbAuthor,
  activeConfiguration,
  cartList,
}) {
  const ref = useRef(null);
  const size = useSlimSize(1420, () => ref.current.offsetWidth);
  const [showSavePopup, setSavePopup] = useState(false);
  const [showRestartPopup, setRestartPopup] = useState(false);

  // Render
  return (
    <div
      className={`ConfigurationOverview ${size}`}
      ref={ref}
    >
      <Cart
        cartList={cartList}
      />
      <ConfigurationActions
        dbAuthor={dbAuthor}
        activeConfiguration={activeConfiguration}
        priceTotal={
          size === 'full' && cartList.pricingEnabled
            ? cartList.priceTotal
            : undefined
        }
        openSavePopup={() => setSavePopup(true)}
        openRestartPopup={() => setRestartPopup(true)}
      />
      {
        showSavePopup && (
        <SaveScreen
          activeConfiguration={activeConfiguration}
          onClose={() => setSavePopup(false)}
        />
        )
      }
      {
        showRestartPopup && (
          <RestartScreen
            onClose={() => setRestartPopup(false)}
          />
        )
      }
    </div>
  );
}
