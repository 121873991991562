import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dispatchSelectPreset } from '../data/actions/dispatchConfiguration';

const getSelectedPresetId = (configuration, presets) => {
  // Is a preset selected in the store?
  if (configuration.data.presetSelection) {
    return configuration.data.presetSelection;
  }
  console.log('preset selection not found in local storage');

  // Find the default preset
  const defaultPreset = presets.data.find((item) => item.is_default);
  if (defaultPreset) {
    return defaultPreset.id;
  }

  // If no default preset, use the first preset
  return presets.data[0];
};

/// Selected Presets are saved only locally in state
/// This class returns the same functionality as useSelectedVariant
export default function useSelectedPreset() {
  const dispatch = useDispatch();
  const [selection, setSelection] = useState(undefined);
  const configuration = useSelector((state) => state.activeConfiguration);
  const configuratorPkg = useSelector((state) => state.configuratorPkg);
  const presets = useSelector((state) => state.presets);

  // Initialize selection
  useEffect(() => {
    if (!selection && configuration.isSuccess && configuratorPkg.isSuccess && presets.isSuccess) {
      // Find the active configurator
      const activeConfigurator = configuratorPkg.data
        .find((item) => item.id === configuration.data.configurator.id);
      if (activeConfigurator) {
        // Apply the initial selection
        const initialPresetId = getSelectedPresetId(configuration, presets);
        setSelection({
          option: {
            id: -10,
            title: '',
            description: '',
            variants: activeConfigurator.presetOption,
          },
          selectedVariant: initialPresetId,
          selectedByUser: false,
        });
        // console.log('initial selection', getSelectedPresetId(configuration, presets));
      }
    }
  }, [configuration, configuratorPkg, presets]);

  // Event handlers
  const onSelectVariant = (presetId) => {
    // Variant was clicked
    if (selection && selection.selectedVariant !== presetId.toString()) {
      setSelection((state) => ({
        ...state,
        selectedVariant: presetId.toString(),
      }));

      // Apply to options
      dispatch(dispatchSelectPreset(presetId));
    }
  };

  return {
    selection,
    selectVariant: onSelectVariant,
  };
}
