import { useEffect, useRef } from 'react';
import { useTransition } from '@react-spring/web';

// Firefox hides the image too fast, Mobile is too weak
// eslint-disable-next-line max-len
const fullAnimation = !navigator.userAgent.match(/firefox|fxios|Android|webOS|iPhone|iPad|BlackBerry|Windows Phone|iPod/i);

// eslint-disable-next-line no-unused-vars
const aniHalt = {
  from: {
    blend: [0, 0],
  },
  enter: [{
    blend: [0, 50],
  }, {
    blend: [0, 50],
    delay: 500,
  }, {
    blend: [0, 100],
  }],
  leave: [{
    blend: [50, 100],
  }, {
    blend: [50, 100],
    delay: 500,
  }, {
    blend: [100, 100],
  }],
};

// eslint-disable-next-line no-unused-vars
const aniWave = {
  from: {
    blend: [-100, 0],
  },
  enter: {
    blend: [0, 100],
  },
  leave: {
    // blend: [0, 200],
    // blend: [100, 100],
    blend:
      fullAnimation
        ? [100, 100]
        : [0, 200],
  },
  config: {
    mass: 1,
    tension: 320,
    friction: 60,
    velocity: 0.6,
    // duration: 1000,
  },
};

// eslint-disable-next-line no-unused-vars
const aniBlend = {
  from: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  leave: {
    opacity: 0,
  },
};

export default function useLayerAnimation(imageStack) {
  const initialized = useRef(false);
  const oldStackCount = useRef(0);

  useEffect(() => {
    if (oldStackCount.current === 0 && imageStack.stack.length > 0) {
      initialized.current = true;
    }

    oldStackCount.current = imageStack.stack.length;
  }, [imageStack]);

  // Animate
  // console.log('useLayerAnimation', imageStack);
  const transitions = useTransition([imageStack], {
    // ...aniBlend,
    // ...aniHalt,
    ...aniWave,
    ...(initialized.current
      ? {}
      : {
        from: {
          opacity: 1,
          blend: [0, 100],
        },
        config: {
          velocity: 0,
        },
      }),
  });

  // Style
  const blend = (a, b) => (
    fullAnimation
      ? `linear-gradient(90deg,
        rgba(0, 0, 0, 0) ${a}%,
        rgba(0, 0, 0, 1) ${a}%,
        rgba(0, 0, 0, 1) ${b}%,
        rgba(0, 0, 0, 0) ${b}%)`
      : `linear-gradient(90deg,
        rgba(0, 0, 0, 1) ${b}%,
        rgba(0, 0, 0, 0) ${b}%)`
  );

  const styleImage = (style) => ({
    maskImage: style.blend.to(blend),
    WebkitMaskImage: style.blend.to(blend),
  });
  const styleLine = (style) => ({
    left: style.blend.to((a, b) => `${b === 0 ? '-10' : b}%`),
  });

  return {
    transitions,
    styleImage,
    styleLine,
  };
}
