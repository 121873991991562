import React from 'react';

import '../../style/components/InputField.scss';

export interface RGInputFieldProps {
  label: string;
  callToAction: any;
  value: string;
  onChange: any;
  onSubmit: any;
  isRequired?: boolean;
}

export default function RGInputField(props: RGInputFieldProps) {
  return (
    <div
      className="RGInputField"
    >
      {
        props.label &&
          <h5>{props.label}</h5>
      }
      <input
        value={props.value}
        placeholder={`${props.callToAction}${props.isRequired ? '*' : ''}`}
        type="url"
        name="Asana Link"
        required
        autoComplete="off"
        onChange={(e : any) => props.onChange(e.target.value)}
        onSubmit={(e : any) => props.onSubmit(e.target.value)}
      />
    </div>
  );
}
