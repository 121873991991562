import React from 'react';
import { RGFormSection } from 'react-rg-designsystem';

import '../style/ToolbarHeader.scss';

export default function ToolbarHeader({ label, description, mode }) {
  return (
    <div
      className="ToolbarHeader"
    >
      <div className="seperator" />
      <RGFormSection
        label={label}
      />
      {
        description
          ? (
            <div className="description">
              <span className={`${mode === 'EDIT' ? 'warning' : ''}`}>
                {description}
              </span>
            </div>
          ) : null
      }
    </div>
  );
}
