import React, { ReactNode, useMemo } from 'react';
import { register } from 'swiper/element/bundle'
import { twMerge } from 'tailwind-merge';

// import styles
import '../../dist/tailwind.css'
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

// register swiper and declare global JSX namespace
register();
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'swiper-container': any;
      'swiper-slide': any;
    }
  }
}

// swiper default options
const defaultOptions = {
  direction: 'horizontal',
  slidesPerView: 1,
  navigation: false,
  pagination: false,
  loop: true,
  autoplay: false,
};

// define proptypes
interface Props {
  children?: Array<ReactNode>;
  images?: Array<string>;
  objectfit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
  swiperOptions?: Object;
  navigation?: boolean | undefined;
  pagination?: boolean;
  autoplay?: boolean;
  className?: string;
}


const RGCarousel: React.FC<Props> = ({ children, images, objectfit, navigation, pagination, autoplay, swiperOptions, className }) => {
  const swiperElRef = React.useRef(null);
  const objectfitVariants = {
    contain: 'object-contain',
    cover: 'object-cover',
    fill: 'object-fill',
    none: 'object-none',
    'scale-down': 'object-scale-down',
  };

  // merge default swiper options with user options and set props
  const options = useMemo(() => {
    const updatedOptions = swiperOptions ? { ...defaultOptions, ...swiperOptions } : defaultOptions;

    updatedOptions.navigation = navigation || false;
    updatedOptions.pagination = pagination || false;
    updatedOptions.autoplay = autoplay || false;

    return updatedOptions;
  }, [swiperOptions, navigation, pagination, autoplay]);

  return (
    <swiper-container ref={swiperElRef} {...options} class={twMerge('swiper-container','w-full overflow-hidden', className)}>
      {children?.map((slide, index) => (
        <swiper-slide key={`slide-${index}`} class={twMerge('w-full h-full object-cover', objectfit && objectfitVariants[objectfit])}>
          {slide}
        </swiper-slide>
      ))}
      {images?.map((image, index) => (
        <swiper-slide key={`image-${index}`} class=''>
          <img src={image} alt={`slide-${index}`} className={twMerge('w-full h-full object-cover', objectfit && objectfitVariants[objectfit])} />
        </swiper-slide>
      ))}
    </swiper-container>
  );
};

export default RGCarousel;
