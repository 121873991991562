import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { dispatchSelectVariant } from '../data/actions/dispatchConfiguration';

function useHIDScanner(handleScan) {
  const buffer = useRef([]);
  const [lastScan, setLastScan] = useState(null);

  function handlePressStart(e) {
    // Is the buffer outdated?
    const bufferIsValid = buffer.current.length > 0
      && e.timeStamp - buffer.current[0].timeStamp < 500;

    // Is it a terminator?
    if (e.key === 'Enter') {
      if (bufferIsValid) {
        // Apply scan and empty buffer
        const scan = buffer.current.map((item) => item.key).join('');
        buffer.current = [];

        // Valid scan?
        if (scan) {
          console.log(`Scanned: ${scan}`);
          e.preventDefault();
          if (handleScan) handleScan(scan);
          setLastScan(scan);
        }
      }
    } else if (e.key !== 'Shift') {
      if (bufferIsValid) {
        // Add to buffer
        buffer.current = [].concat([...buffer.current, e]);
      } else {
        // Start new buffer
        buffer.current = [e];
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handlePressStart);
  }, []);

  return lastScan;
}

// -------------------------------------------------------------------------------------------

export default function useScanner(activeConfigurator) {
  const configurator = useRef(activeConfigurator);
  const dispatch = useDispatch();

  // Update configurator reference
  useEffect(() => {
    configurator.current = activeConfigurator;
  }, [activeConfigurator]);

  // Event handler
  const handleScan = (code) => {
    if (configurator.current) {
      // console.log('Has active configurator');
      configurator.current.scenes.forEach((scene) => {
        scene.options.forEach((option) => {
          option.variants.forEach((variant) => {
            if (
              variant.scanner_tags
              && variant.scanner_tags
                .split(/\r?\n/)
                .includes(code)
            ) {
              console.log(`Scanned: ${code} > ${option.title}.${variant.title}`);
              dispatch(dispatchSelectVariant(option.id, variant.id));
            }
          });
        });
      });
    }
  };

  // Run the scanner
  useHIDScanner(handleScan);
}

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
