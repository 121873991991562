/* eslint-disable no-unused-vars */
import { languages, defaultLanguage } from '../localization';

const detectBrowserLanguage = () => {
  const browserLanguage = navigator.language || navigator.userLanguage;
  for (let iLanguage = 0; iLanguage < languages.length; iLanguage += 1) {
    const language = languages[iLanguage];
    if (browserLanguage.includes(language)) {
      console.log(browserLanguage);
      return language;
    }
  }
  return defaultLanguage;
};

export const getDefaultObject = () => ({
  language: detectBrowserLanguage(),
});

export default function localizationReducer(state, action) {
  // Currently only the autodetected language is supported
  return getDefaultObject();
}
