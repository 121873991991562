/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
// import WebSocket from 'websocket';

import useActiveConfigs from './useActiveConfigs';

// -------------------------------------------------------------------------------------------

const getOptionChange = (optionSelection) => ({
  optionTag: optionSelection.option.folder_name,
  variantTag: optionSelection.option.variants
    .findIndex((variant) => variant.id.toString() === optionSelection.selectedVariant.toString()),
});

// -------------------------------------------------------------------------------------------

export default function usePixelStreaming(iframeRef, configuratorId, activeScene) {
  const { activeConfiguration } = useActiveConfigs(configuratorId);
  const [oldOptionChanges, setOldOptionChanges] = useState([]);
  // const { sendCommand } = usePixelStreamingConnection(1920, 1080);

  const sendCommand = (data) => {
    console.log('sendCommand', data);
    const command = {
      cmd: 'sendToUe4',
      value: data,
    };
    iframeRef.current.contentWindow.postMessage(JSON.stringify(command), '*');
  };

  // Send individual options
  // !!!!!! It might be smarter to send the whole configuration at once
  const applyOption = (optionChange) => {
    sendCommand(optionChange);
  };

  // Apply options when configuration changes
  useEffect(() => {
    if (activeConfiguration) {
      // Convert configuration to array of option changes
      const newOptionChanges = activeConfiguration.optionSelections.map(getOptionChange);

      // Apply the option for all changes compared to the old state
      newOptionChanges.forEach((newOptionChange) => {
        const oldOptionChange = oldOptionChanges.find((change) => (
          newOptionChange.optionTag === change.optionTag
        ));

        if (
          !oldOptionChange
          || newOptionChange.variantTag !== oldOptionChange.variantTag
        ) {
          applyOption(newOptionChange);
        }
      });

      // Update old state
      setOldOptionChanges(newOptionChanges);
    }
  }, [activeConfiguration]);

  // Switch scene when active scene changes
  useEffect(() => {
    console.log('Switch scene', activeScene);
    if (
      activeScene
      && activeScene.viewpoints
      && activeScene.viewpoints.length > 0
    ) {
      sendCommand({ activeScene: activeScene.viewpoints[0].folder_name });
    }
  }, [activeScene]);
}

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
