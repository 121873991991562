import React, { useEffect, useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import {
  RGButton,
  RGCardScroller,
  // RGFormContainer,
} from 'react-rg-designsystem';

import paths from '../data/paths';
import useSelectedVariant from '../hooks/useSelectedVariant';
import useActiveConfigs from '../hooks/useActiveConfigs';
import useVariantDependencies from '../hooks/useVariantDependencies';

import Option from './Option';

import '../style/OptionList.scss';

library.add(faPlus);

export default function OptionList({
  sceneId,
  options,
  configuratorId,
  mode,
  isMobile,
}) {
  // const cardScrollerRef = useRef(null);
  const { activeConfigurator, activeConfiguration } = useActiveConfigs(
    configuratorId,
    // true,
  );
  const { filterOptions: filterDynamicOptions } = useVariantDependencies(
    activeConfigurator,
    activeConfiguration,
    // true,
  );
  const [filteredOptions, setFilteredOptions] = useState([]);

  // Filter options
  useEffect(() => {
    if (activeConfigurator && activeConfiguration) {
      // Filter and sort Options
      setFilteredOptions(
        filterDynamicOptions(options)
          .filter((option) => option.variants.length > 1)
          .sort((a, b) => a.order - b.order),
      );

      // cardScrollerRef.current.resetScroll();
    }
  }, [options, activeConfigurator, activeConfiguration]);

  // Build Options List
  const items = filteredOptions
    .map((item) => (
      <Option
        key={item.id}
        option={item}
        configuratorId={configuratorId}
        selectionMotor={useSelectedVariant}
        mode={mode}
        isMobile={isMobile}
      />
    ));
  const keys = filteredOptions.map((item) => item.id);

  // Add edit button?
  if (mode === 'EDIT') {
    items.push(
      <RGButton
        key="add-option"
        className="create"
        label="Option"
        icon={faPlus}
        // emphasis="high"
        href={paths.EDITOR_OPTION_CREATE}
        target="_blank"
      />,
    );
    keys.push('create');
  }

  // Render
  return (
    <RGCardScroller
      key={sceneId}
      className="OptionList"
      // ref={cardScrollerRef}
      keys={keys}
      autoFocus={isMobile}
    >
      {
        items
      }
    </RGCardScroller>
  );
}
