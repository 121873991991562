import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';

import {
  RGPopup,
  RGButton,
  RGButtonEnums,
} from 'react-rg-designsystem';

import useLocalization from '../hooks/useLocalization';
import useRestart from '../hooks/useRestart';

import '../style/RestartScreen.scss';

library.add(faCheckCircle);

export default function SaveScreen({
  onClose,
}) {
  const translate = useLocalization();
  const { restart } = useRestart();

  return (
    <RGPopup
      className="RestartScreen"
      title={translate('restart.title')}
      description={translate('restart.description')}
      onClose={onClose}
    >
      <RGButton
        label={translate('continue')}
        onClick={() => { restart(); }}
        emphasis={RGButtonEnums.emphasis.high}
        size={RGButtonEnums.size.large}
      />
    </RGPopup>
  );
}
