/* eslint-disable default-param-last */
import baseFetchReducer, { getDefaultFetchState } from './baseFetch';

const configurationsReducer = (
  state = getDefaultFetchState(),
  action,
) => baseFetchReducer(
  'CONFIGURATIONS',
  state,
  action,
);
export default configurationsReducer;
