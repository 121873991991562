import React from 'react';
import '../../style/components/Sidebar.scss';

export interface RGSidebarTop2DesktopProps {
  labelParent: string;
  labelChild: string;
}

export default function RGSidebarTop2Desktop(props: RGSidebarTop2DesktopProps) {
  return (
    <div
      className="RGSidebar top2 desktop"
    >
      <div className={`parent container ${props.labelChild ? 'hasChild' : 'noChild'}`}>
        <h2>{props.labelParent}</h2>
      </div>
      {
        props.labelChild
          ? (
            <div className="child container">
              <h2>{props.labelChild}</h2>
            </div>
          )
          : null
      }
    </div>
  );
}
