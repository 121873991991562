import React from 'react';

import RGBrandLogo from './BrandLogo';

import '../../style/components/BrandHeader.scss';

export interface RGBrandHeaderProps {
  alt: string,
  src?: string,
}

export default function RGBrandHeader(props: RGBrandHeaderProps) {
  return (
    <div
      className="RGBrandHeader"
    >
      <RGBrandLogo
        {...props}
      />
    </div>
  );
}
