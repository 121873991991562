import { useEffect, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  dispatchLocalConfiguration,
  dispatchLoadConfiguration,
  dispatchNewConfiguration,
} from '../data/actions/dispatchConfiguration';

export default function useConfiguration() {
  const dispatch = useDispatch();

  const { dbAuthor, configuratorId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const code = useRef(undefined);
  const configuration = useSelector((state) => state.activeConfiguration);

  // Catch all changes of the code and activate a configuration
  useEffect(() => {
    const urlCode = searchParams.get('code');
    if (code.current === undefined) {
      // No code loaded
      if (!urlCode || urlCode === 'new') {
        console.log('No code loaded, trying to load local save');
        dispatch(dispatchLocalConfiguration(dbAuthor, configuratorId));
      } else {
        // Load existing code
        console.log('No code loaded, loading from server');
        dispatch(dispatchLoadConfiguration(dbAuthor, configuratorId, urlCode));
      }
    } else if (code.current !== urlCode) {
      // Code changed
      if (!urlCode || urlCode === 'new') {
        // Create empty configuration
        console.log('Changed to new code, creating new configuration');
        dispatch(dispatchNewConfiguration(dbAuthor, configuratorId));
      } else {
        // Load different, existing code
        console.log('Code changed, loading from server');
        dispatch(dispatchLoadConfiguration(dbAuthor, configuratorId, urlCode));
      }
    } else {
      // Code did not change
    }
  }, [searchParams]);

  // Catch changes of the configuration and update the code
  useEffect(() => {
    if ((configuration.isSuccess || configuration.isError) && !configuration.isFetching) {
      // Did the configuration load successfully?
      if (!configuration.isError) {
        // Is the code new?
        const newCode = configuration.data.code;
        if (newCode !== code.current) {
          // Switch to the new code
          console.log(`Changed code: ${code.current} > ${newCode}`);
          code.current = newCode;

          // Update the url
          searchParams.set('code', newCode);
          if (configuration.data.urlParameters) {
            const urlParameters = JSON.parse(configuration.data.urlParameters);
            Object.keys(urlParameters).forEach((key) => {
              searchParams.set(key, urlParameters[key]);
            });
          }
          setSearchParams(searchParams);
        }
      }
    }
  }, [configuration]);

  return {
    isSaved: code.current !== undefined && code.current !== 'new',
  };
}
