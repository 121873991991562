/* eslint-disable default-param-last */
import baseFetchReducer, { getDefaultFetchState } from './baseFetch';

const brandReducer = (
  state = getDefaultFetchState(),
  action,
) => baseFetchReducer(
  'BRAND',
  state,
  action,
);
export default brandReducer;
