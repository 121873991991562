import React from 'react';
import { useParams } from 'react-router-dom';

import {
  RGBottomCard,
} from 'react-rg-designsystem';

import useCart from '../hooks/useCart';
import useLocalization from '../hooks/useLocalization';
import useAnalyticsClickHandler from '../hooks/useAnalytics';
import analyticsEvents from '../data/analytics';
import { formatPrice } from '../data/localization';

import ConfigurationOverview from './ConfigurationOverview';

import '../style/CartBar.scss';

export default function CartBar({ emphasis }) {
  const {
    dbAuthor,
    configuratorId,
  } = useParams();
  const activeConfiguratorId = parseInt(configuratorId, 10);
  const {
    activeConfigurator,
    activeConfiguration,
    cartList,
  } = useCart(activeConfiguratorId);
  const handleOpen = useAnalyticsClickHandler(analyticsEvents.configure.cart);
  const translate = useLocalization();

  // Validate data
  if (!activeConfigurator) {
    return null;
  }

  // Render
  return (
    <RGBottomCard
      className="CartBar"
      minimizedContent={(
        <h5>
          {
            cartList.pricingEnabled
              ? `${formatPrice(cartList.priceTotal)}`
              : translate('cart.bar')
          }
        </h5>
      )}
      detailsContent={(
        <ConfigurationOverview
          dbAuthor={dbAuthor}
          activeConfiguration={activeConfiguration}
          cartList={cartList}
        />
      )}
      emphasis={emphasis}
      onOpen={handleOpen}
    />
  );
}
