import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faDigging } from '@fortawesome/pro-light-svg-icons';

import '../../style/components/OfflineBlocker.scss';

export interface RGOfflineBlockerProps {
  children?: React.ReactNode;
  title?: string;
  message?: string;
  icon?: IconDefinition;
}

export default function RGOfflineBlocker(props: RGOfflineBlockerProps) {
  return (
    <div
      className="RGOfflineBlocker"
    >
      <FontAwesomeIcon
        icon={props.icon || faDigging}
        className="faIcon icon on-elevation"
      />
      <h2>{props.title}</h2>
      <h3>{props.message}</h3>
      <div className="content">
        { props.children }
      </div>
    </div>
  );
}
