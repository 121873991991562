/* eslint-disable no-unused-vars */
import React from 'react';

import {
  RGButton,
  RGButtonEnums,
  RGInputField,
  RGOfflineBlocker,
  RGBrandHeader,
} from 'react-rg-designsystem';

import useLocalization from '../hooks/useLocalization';
import { useBrandImages } from '../hooks/useBrand';

import BrandToolbarDesktop from './BrandToolbarDesktop';
import ToolbarHeader from './ToolbarHeader';

import moodImage from '../theme/Konfi-Mood-01.png';
import '../style/OfflineScreen.scss';
import useOfflineMessage from '../hooks/useOfflineMessage';

function OfflineScreenDesktop({
  commonComponents,
  title,
  message,
}) {
  // Render
  return (
    <>
      <BrandToolbarDesktop>
        {/* <ToolbarHeader
          label={title}
          description={message}
          mode="VIEW"
        /> */}
        <div />
      </BrandToolbarDesktop>
      {commonComponents.view}
    </>
  );
}

function OfflineScreenMobile({
  commonComponents,
  title,
  message,
}) {
  const {
    alt,
    logoOnBrandSurface,
    // logoOnBrightSurface,
  } = useBrandImages();

  // Render
  return (
    <>
      <RGBrandHeader
        src={logoOnBrandSurface}
        alt={alt}
      />
      {commonComponents.view}
      <div className="bottomPanel">
        <ToolbarHeader
          label={title}
          description={message}
          mode="VIEW"
        />
      </div>
    </>
  );
}

export default function OfflineScreen({ isMobile }) {
  const translate = useLocalization();
  const offlineMessage = useOfflineMessage();

  const messageTitle = offlineMessage.custom_title || translate('offline.title');
  const messageText = offlineMessage.custom_message || translate('offline.message');

  // Event handlers
  const handleSendNotification = () => {
    console.log('Send notification');
  };

  // Common components
  const commonComponents = {
    view: (
      <div className="view">
        <img
          src={moodImage}
          alt="Mood Visual"
        />
        <RGOfflineBlocker
          title={isMobile ? '' : messageTitle}
          message={isMobile ? '' : messageText}
        >
          {/* <div
            className="notifyMe"
          >
            <RGInputField
              type="email"
              callToAction={translate('email')}
              onSubmit={handleSendNotification}
            />
            <RGButton
              label={translate('offline.notifyMe')}
              onClick={handleSendNotification}
              emphasis={RGButtonEnums.emphasis.high}
              size={RGButtonEnums.size.large}
            />
          </div> */}
        </RGOfflineBlocker>
      </div>
    ),
  };

  // Render
  return (
    <div
      className={`OfflineScreen ${isMobile ? 'mobile' : 'desktop'}`}
    >
      {
        isMobile
          ? (
            <OfflineScreenMobile
              translate={translate}
              commonComponents={commonComponents}
              title={messageTitle}
              message={messageText}
            />
          )
          : (
            <OfflineScreenDesktop
              translate={translate}
              commonComponents={commonComponents}
              title={messageTitle}
              message={messageText}
            />
          )
      }
    </div>
  );
}
