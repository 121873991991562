import { combineReducers } from 'redux';

import localizationReducer from './localizationReducer';
import configuratorPkgReducer from './configuratorPkgReducer';
import configuratorFilesReducer from './configuratorFilesReducer';
import presetsReducer from './presetsReducer';
import configurationReducer from './configurationReducer';
import configurationsReducer from './configurationsReducer';
import brandReducer from './brandReducer';
import projectSettingReducer from './projectSettingReducer';

const allReducer = combineReducers({
  configuratorPkg: configuratorPkgReducer,
  configuratorFiles: configuratorFilesReducer,
  presets: presetsReducer,
  activeConfiguration: configurationReducer,
  configurations: configurationsReducer,
  localization: localizationReducer,
  brand: brandReducer,
  projectSettings: projectSettingReducer,
});
export default allReducer;
