import React from 'react';
import { useSearchParams } from 'react-router-dom';

import useLocalization from '../hooks/useLocalization';
import { formatDate, formatTime } from '../data/time';
import paths, { getConfigurationPath } from '../data/paths';

import QRCode from './QRCode';

import '../style/ContractHeader.scss';

export default function ContractHeader({
  dbAuthor,
  configurator,
  configuration,
}) {
  const translate = useLocalization();
  const [searchParams] = useSearchParams();

  return (
    <div
      className="ContractHeader"
    >
      <div className="identifier">
        {/* <h2>{translate('contract.title')}</h2> */}
        <p>{configurator.title}</p>
        <p>{`Nr: ${searchParams.get('nr')}`}</p>
        <p>{`${formatDate(new Date(configuration.timestamp))} ${formatTime(new Date(configuration.timestamp))}`}</p>
      </div>
      <div className="spacer" />
      <div className="access">
        <div className="text">
          <p className="fine">{translate('accessCode')}</p>
          <h4>{configuration.code}</h4>
        </div>
        <QRCode
          qrLink={getConfigurationPath(dbAuthor, configuration)}
          textLink={paths.CONFIGURATION_ENTER}
        />
      </div>
    </div>
  );
}
