import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { RGButton, RGButtonEnums } from 'react-rg-designsystem';

import useLocalization from '../hooks/useLocalization';
import usePrintContract from '../hooks/usePrintContract';
import useAnalyticsClickHandler from '../hooks/useAnalytics';
import { formatPrice } from '../data/localization';
import analyticsEvents from '../data/analytics';

import ContractDocument from './ContractDocument';

import '../style/ConfigurationActions.scss';

const ContractToPrint = React.forwardRef((props, ref) => {
  const preloadedBrand = useSelector((state) => state.brand);

  return (
    <div
      className="ContractToPrint"
      ref={ref}
    >
      <ContractDocument
        forceFullSize
        preloadedBrand={preloadedBrand.data}
      />
    </div>
  );
});

function CartPrintButton({ translate }) {
  const componentRef = useRef(null);
  const print = usePrintContract(componentRef.current);
  const handleClick = useAnalyticsClickHandler(analyticsEvents.cart.print, print);

  return (
    <>
      <ContractToPrint ref={componentRef} />
      <RGButton
        label={translate('print')}
        onClick={handleClick}
        size="large"
      />
    </>
  );
}

export default function ConfigurationActions({
  priceTotal,
  openSavePopup,
  openRestartPopup,
  // mode,
}) {
  const translate = useLocalization();
  const handleClickSave = useAnalyticsClickHandler(
    analyticsEvents.cart.save,
    openSavePopup,
  );
  const handleClickRestart = useAnalyticsClickHandler(
    analyticsEvents.cart.restart,
    openRestartPopup,
  );

  // Render
  return (
    <div className="ConfigurationActions">
      {
        priceTotal !== undefined
          ? (
            <div className="price">
              <h4>{translate('cart.total.title')}</h4>
              <h2>{formatPrice(priceTotal)}</h2>
            </div>
          ) : null
      }
      <RGButton
        label={translate('save')}
        onClick={handleClickSave}
        emphasis={RGButtonEnums.emphasis.high}
        size={RGButtonEnums.size.large}
      />
      <CartPrintButton translate={translate} />
      <RGButton
        label={translate('startAgain')}
        onClick={handleClickRestart}
        emphasis={RGButtonEnums.emphasis.default}
        size={RGButtonEnums.size.large}
      />
      {
      false
        ? (
          <RGButton
            label={translate('makeAppointment')}
            href=""
            size={RGButtonEnums.size.large}
          />
        ) : null
      }
    </div>
  );
}
