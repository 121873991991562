import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

/// !!!!!! Refactoring
/// This should be handled by the store, not a hook

export default function useActiveConfigs(
  configuratorId,
  enableLogging = false,
) {
  const activeConfiguration = useSelector((state) => state.activeConfiguration);
  const configuratorPkg = useSelector((state) => state.configuratorPkg);
  const [activeData, setActiveData] = useState({
    activeConfiguration: null,
    activeConfigurator: null,
  });

  // Gather required data for filter
  useEffect(() => {
    if (enableLogging) {
      console.log('data changed', configuratorId, configuratorPkg, activeConfiguration);
    }
    if (
      configuratorId
      && configuratorPkg.isSuccess
      && activeConfiguration.isSuccess
    ) {
      if (enableLogging) {
        console.log('data available');
      }
      setActiveData({
        activeConfiguration: { ...activeConfiguration.data },
        activeConfigurator: {
          ...configuratorPkg.data.find((item) => (
            item.id.toString() === configuratorId.toString())),
        },
      });
    } else {
      // console.log(configuratorPkg, activeConfiguration);
    }
  }, [configuratorId, configuratorPkg, activeConfiguration]);

  return activeData;
}
