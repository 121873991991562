import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import useSave from './useSave';

export default function usePrintContract(componentRef) {
  const [isPrinting, setIsPrinting] = useState(false);
  const isSaving = useRef(false);
  const [searchParams] = useSearchParams();

  const { isSaved, save, activeConfiguration } = useSave();
  const printNow = useReactToPrint({
    content: () => componentRef,
  });

  const delayedPrint = () => {
    printNow();
    setIsPrinting(false);
    isSaving.current = false;
  };

  // Work on printing
  useEffect(() => {
    if (isPrinting) {
      if (isSaved()) {
        // Print next frame to allow a rerender
        setTimeout(delayedPrint, 1);
      } else if (!isSaving.current) {
        // Saving required
        isSaving.current = true;
        save();
      }
    }
  }, [activeConfiguration, isPrinting, searchParams]);

  return () => setIsPrinting(true);
}
