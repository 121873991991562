import { utils, writeFile } from 'xlsx-js-style';

// const exportToCSV = (csvData, fileName, wscols) => {
const exportToXlsx = (tableNames, tableDatasets, fileName, applyStyle) => {
  // Create workbook
  const workbook = utils.book_new();

  // Add tables
  for (let i = 0; i < tableDatasets.length; i += 1) {
    const tableDataset = tableDatasets[i];
    const worksheet = utils.json_to_sheet(
      tableDataset,
    );

    // Stylize?
    let styledWorksheet;
    if (applyStyle) {
      styledWorksheet = applyStyle(worksheet);
    }

    // Add the worksheet to the workbook
    utils.book_append_sheet(workbook, styledWorksheet, tableNames[i]);
    // console.log(wb, ws, workbook, styledWorksheet);
  }

  // Save
  const fileExtension = '.xlsx';
  writeFile(workbook, fileName + fileExtension);
};
export default exportToXlsx;
