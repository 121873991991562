import React from 'react';

import SceneView from './SceneView';

import '../style/MoodImage.scss';

export default function MoodImage({
  scene,
  isMobile,
}) {
  if (!scene) {
    return null;
  }

  return (
    <SceneView
      className="MoodImage"
      scene={scene}
      isMobile={isMobile}
    />
  );
}
