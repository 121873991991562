import axios from 'axios';
import paths from '../paths';

const prepareConfigurationForSaving = (configuration) => {
  const fixedConfiguration = JSON.parse(JSON.stringify(configuration));

  // Replace Options with their IDs
  fixedConfiguration.configurator = configuration.configurator.id;
  for (let i = 0; i < configuration.optionSelections.length; i += 1) {
    fixedConfiguration.optionSelections[i].option = configuration.optionSelections[i].option.id;
  }

  // Clear ids that should be generated by the server
  delete fixedConfiguration.id;
  for (let i = 0; i < configuration.optionSelections.length; i += 1) {
    delete fixedConfiguration.optionSelections[i].id;
  }

  return fixedConfiguration;
};

export function dispatchLocalConfiguration(dbAuthor, configuratorId) {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_LOCAL_CONFIGURATION',
      payload: axios.get(`${paths.API_CONFIGURATOR}/configuration/new/${dbAuthor}/${configuratorId}`),
    });
  };
}

export function dispatchNewConfiguration(dbAuthor, configuratorId) {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_ACTIVE_CONFIGURATION',
      payload: axios.get(`${paths.API_CONFIGURATOR}/configuration/new/${dbAuthor}/${configuratorId}`),
    });
  };
}

export function dispatchLoadConfiguration(dbAuthor, configuratorId, code) {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_ACTIVE_CONFIGURATION',
      payload: axios.get(`${paths.API_CONFIGURATOR}/configuration/existing/${dbAuthor}/${configuratorId}/${code}`),
    });
  };
}

export function dispatchSaveConfiguration(dbAuthor, configuration) {
  // Clean data
  const fixedConfiguration = prepareConfigurationForSaving(configuration);

  // Send
  return (dispatch) => {
    dispatch({
      type: 'FETCH_ACTIVE_CONFIGURATION',
      payload: axios.post(
        `${paths.API_CONFIGURATOR}/configuration/author/${dbAuthor}`,
        fixedConfiguration,
      ),
    });
  };
}

export function dispatchLinkContact(dbAuthor, code, contact) {
  return (dispatch) => {
    dispatch({
      type: 'LINK_CONTACT',
      payload: axios.put(
        `${paths.API_CONFIGURATOR}/configuration/contact/${dbAuthor}/${code}`,
        contact,
      ),
    });
  };
}

export function dispatchSelectVariant(optionId, variantId) {
  return (dispatch) => {
    dispatch({
      type: 'SELECT_VARIANT',
      payload: {
        optionId,
        variantId,
      },
    });
  };
}

export function dispatchSelectPreset(presetId) {
  return (dispatch) => {
    dispatch({
      type: 'SELECT_PRESET',
      payload: {
        presetId,
      },
    });
  };
}
