import React, { useState } from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../style/components/FormLayout.scss';

library.add(faChevronDown, faChevronUp);

export interface RGFormSegmentProps {
  className : string;
  label: string;
  startOpen: boolean;
  children: any[];
}

export default function RGFormSegment(props: RGFormSegmentProps) {
  const [isOpen, setOpen] = useState(props.startOpen);

  const handleClick = (e : any) => {
    e.preventDefault();
    console.log("Toggle open");
    setOpen(state => !state);
  };

  return (
    <div
      className={`RGFormSegment ${props.className}`}
    >
      <button
        className="segmentHead"
        type="button"
        onClick={handleClick}
      >
        <span>{props.label}</span>
        <FontAwesomeIcon
          icon={isOpen ? faChevronUp : faChevronDown}
          className="faIcon icon on-elevation"
        />
      </button>
      {
        isOpen
          ? (
            <div className="content">
              {props.children}
            </div>
          )
          : null
      }
    </div>
  );
}
