import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

export default function useLayout() {
  const [layout, setLayout] = useState(true);

  // Recognize the required layout
  const handleWindowOrientationChange = () => {
    const nowIsPortrait = window.screen.orientation.type === 'portrait-primary';

    // Apply
    if (layout.isPortrait !== nowIsPortrait) {
      setLayout((state) => ({
        ...state,
        isPortrait: nowIsPortrait,
      }));
    }
  };

  // Apply isMobile
  useEffect(() => {
    setLayout((state) => ({
      ...state,
      isMobile,
    }));
  }, [isMobile]);

  // Listen to window resize events
  useEffect(() => {
    // Apply orientation
    handleWindowOrientationChange();

    // Subscribe to changes
    window.screen.orientation.addEventListener('change', handleWindowOrientationChange);
    // window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.screen.orientation.removeEventListener('change', handleWindowOrientationChange);
      // window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return layout;
}
