export function checkVariantIsSelected(
  activeConfiguration,
  optionId,
  variantIds,
  objectName,
  logExcludes,
) {
  // Find the relevant selection
  const selection = activeConfiguration.optionSelections.find((item) => (
    item.option.id.toString() === optionId.toString()
  ));
  if (selection) {
    // Is any of the variants selected?
    const variantIsSelected = variantIds.findIndex(
      (id) => id.toString() === selection.selectedVariant,
    ) !== -1;

    // Log if the object was excluded
    if (logExcludes && !variantIsSelected) {
      console.log(`Scene "${objectName}" was excluded by option "${selection.option.title}"`);
    }
    return variantIsSelected;
  }

  // The option that the variants depend on is not even used in the configuration
  console.log('no selection found for option', optionId);
  return true;
}

export default function useVariantDependencies(
  activeConfigurator,
  activeConfiguration,
  enableLogging = false,
) {
  // Filter
  const filterObjects = (objects, dynamicObjectKey, logExcludes) => {
    if (activeConfigurator && activeConfiguration) {
      if (enableLogging) {
        console.log('has active configurator and configuration');
      }

      // Filter the objects
      return objects.filter((object) => {
        // Look for a filter
        const filter = activeConfigurator[dynamicObjectKey][object.id];
        if (filter) {
          const dependencies = filter.depends_on;

          // Check if all dependencies are met
          const dependenciesMet = Object.keys(dependencies).every((key) => checkVariantIsSelected(
            activeConfiguration,
            key,
            dependencies[key],
            object.title,
            logExcludes,
          ));
          return dependenciesMet;
        }
        // Not filtered
        return true;
      });
    }

    if (enableLogging) {
      console.log('no active configuration or configurator', activeConfigurator, activeConfiguration);
    }
    return objects;
  };
  const filterOptions = (options) => filterObjects(options, 'dynamic_options', false);
  const filterScenes = (scenes) => filterObjects(scenes, 'dynamic_scenes', enableLogging);

  return { filterOptions, filterScenes };
}
