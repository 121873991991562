import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';

import '../../style/components/SlideshowNavigation.scss';

library.add(faChevronLeft, faChevronRight);

export interface RGSlideshowNavigationProps {
  swiperAPI: any,
  currentSlide: number,
  slideCount: number,
}

export default function RGSlideshowNavigation(props: RGSlideshowNavigationProps) {
  // Only render if necessary
  if (props.slideCount < 2) return null;

  // Clean data
  const cleanedCurrentSlide = props.currentSlide === undefined ? 0 : props.currentSlide;

  // Event handlers
  const handleBack = () => {
    props.swiperAPI.current.slidePrev();
  };

  const handleNext = () => {
    props.swiperAPI.current.slideNext();
  };

  // Render
  return (
    <div
      className="RGSlideshowNavigation"
    >
      <button
        className="back"
        type="button"
        onClick={handleBack}
      >
        <FontAwesomeIcon
          className="faIcon icon"
          icon={faChevronLeft}
        />
      </button>
      <p>{`${cleanedCurrentSlide + 1} / ${props.slideCount}`}</p>
      <button
        className="next"
        type="button"
        onClick={handleNext}
      >
        <FontAwesomeIcon
          className="faIcon icon"
          icon={faChevronRight}
        />
      </button>
    </div>
  );
}
