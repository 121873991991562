import React from 'react';
import '../../style/components/SidebarLeftList.scss';

export interface RGSidebarLeftListProps {
  isMobile: boolean,
  children: any[];
}

export default function RGSidebarLeftList(props: RGSidebarLeftListProps) {
  return (
    <div
      className={`RGSidebarLeftList ${props.isMobile ? 'mobile' : 'desktop'}`}
    >
      {props.children}
    </div>
  );
}
