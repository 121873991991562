import { useEffect, useState } from 'react';
import { loadStateFromStorage, saveStateToStorage } from '../data/localStorage';

import useOfflineMessage from './useOfflineMessage';

export const stages = {
  ONBOARDING: 'ONBOARDING',
  CONFIGURE: 'CONFIGURE',
  CART: 'CART',
  OFFLINE: 'OFFLINE',
};

export default function useConfiguratorStage(configuratorId, isSaved) {
  const defaultStageData = () => ({ data: { stage: stages.ONBOARDING } });
  const [activeStage, setStage] = useState(defaultStageData());
  const offlineMessage = useOfflineMessage(); // configuratorId); not yet needed

  const trySetStage = (newStage) => {
    // Can only set stage if online
    if (activeStage !== stages.OFFLINE) {
      setStage(newStage);
    }
  };

  // Ensure is online
  useEffect(() => {
    if (offlineMessage) {
      // Does any state disable the whole configurator?
      const isOffline = offlineMessage.all_configurators_unavailable;

      // Go to offline?
      if (isOffline) {
        setStage({ data: { stage: stages.OFFLINE } });
      }
    }
  }, [offlineMessage]);

  // Apply stage change
  const updateStage = (newStage) => {
    // console.log(newStage);
    const newState = { data: { stage: newStage } };
    trySetStage(newState);

    saveStateToStorage(
      'activeStages',
      newState,
      configuratorId,
    );
  };

  // Load state from local storage
  useEffect(() => {
    if (configuratorId) {
      const foundState = loadStateFromStorage(
        'activeStages',
        activeStage,
        null,
        configuratorId,
      );

      // Update the loaded state
      if (foundState.data) {
        trySetStage(foundState);
      }
    }
  }, [configuratorId]);

  // Skip onboarding for saved configs
  if (activeStage.data.stage === stages.ONBOARDING && isSaved) {
    updateStage(stages.CONFIGURE);
  }

  // Return stage
  return {
    stages,
    // activeStage: stages.OFFLINE,
    activeStage: activeStage.data.stage,
    setStage: updateStage,
  };
}
