/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import axios from 'axios';
// import { utils } from 'xlsx';
import { utils } from 'xlsx-js-style';

import paths from './paths';
import exportToXlsx from './xlsx';

function variantPricesToTableData(variantPrices, onlyOrdered) {
  // Sort variantPrices by keys into array
  const variantPricesArray = Object.keys(variantPrices)
    .sort()
    .map((key) => variantPrices[key]);

  // Create table data
  const tableData = variantPricesArray.map((variantPrice) => ({
    IdOption: variantPrice.option.id,
    Option: variantPrice.option.title,
    IdVariant: variantPrice.variant.id,
    Variant: variantPrice.variant.title,
    ...variantPrice.pricesForApartments.reduce((prev, curr) => ({
      ...prev,
      [curr.apartmentNr]: !onlyOrdered || curr.isOrdered ? curr.price : '',
    }), {}),
  }));

  // Sort by option title and variant title
  tableData.sort((a, b) => {
    if (a.Option < b.Option) return -1;
    if (a.Option > b.Option) return 1;
    if (a.Variant < b.Variant) return -1;
    if (a.Variant > b.Variant) return 1;
    return 0;
  });

  return tableData;
}

function applyStyleToPriceTable(worksheet) {
  // Stylize all headers
  const headerRow = 0;
  const headerColumn = 0;
  const headerColumnEnd = utils.decode_cell(worksheet['!ref']).c;
  for (let column = headerColumn; column <= headerColumnEnd; column += 1) {
    const cellRef = utils.encode_cell({ c: column, r: headerRow });
    const isApartmentColumn = column > 3;
    if (worksheet[cellRef]) {
      worksheet[cellRef].s = {
        fill: {
          fgColor: {
            rgb: isApartmentColumn ? 'DAEEF3' : 'DDDDDD',
          },
        },
        font: {
          bold: true,
          sz: '11',
        },
        alignment: {
          horizontal: isApartmentColumn ? 'right' : 'left',
        },
      };
    }
  }

  // Stylize all IDs
  const idColumns = [0, 2];
  for (let i = 0; i < idColumns.length; i++) {
    const column = idColumns[i];
    const numberOfRows = utils.decode_cell(worksheet['!ref']).r;
    for (let row = 0; row <= numberOfRows; row += 1) {
      const cellRef = utils.encode_cell({ c: column, r: row });
      if (worksheet[cellRef]) {
        // Set font size
        if (!worksheet[cellRef].s) {
          worksheet[cellRef].s = { font: {} };
        }
        if (!worksheet[cellRef].s.font) {
          worksheet[cellRef].s.font = {};
        }
        worksheet[cellRef].s.font.sz = '8';
      }
    }
  }

  // Add right border to column D with the variant names
  const variantColumn = 3;
  const numberOfRows = utils.decode_cell(worksheet['!ref']).r;
  for (let row = 0; row <= numberOfRows; row += 1) {
    const cellRef = utils.encode_cell({ c: variantColumn, r: row });
    if (worksheet[cellRef]) {
      // Set border
      if (!worksheet[cellRef].s) {
        worksheet[cellRef].s = { border: {} };
      }
      if (!worksheet[cellRef].s.border) {
        worksheet[cellRef].s.border = {};
      }
      worksheet[cellRef].s.border.right = {
        style: 'thin',
        color: {
          rgb: '000000',
        },
      };
    }
  }

  // Set width of columns
  const columnWidths = [3, 30, 3, 50];
  worksheet['!cols'] = columnWidths.map((width) => ({ wch: width }));

  // Translate column names
  worksheet.D1.v = 'Variante';

  return worksheet;
}

function saveAsOrdersTable(dbAuthor, variantPrices) {
  // const rows = Object.keys(variantPrices).map((d) => Object.values(d).join(';'));

  // Get Timestamp
  const date = new Date();
  const timestamp = `${date.getFullYear()}_${date.getMonth() + 1}_${date.getDate()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}`;

  // Create CSV file
  exportToXlsx(
    [
      'Preise',
      'Bestellungen',
    ],
    [
      variantPricesToTableData(variantPrices, false),
      variantPricesToTableData(variantPrices, true),
    ],
    `${dbAuthor}-orders-${timestamp}`,
    applyStyleToPriceTable,
  );
}

export default function downloadOrdersTable(dbAuthor, configurationCodes) {
  // Call API
  axios
    .get(`${paths.API_CONFIGURATOR}/configurations/orders/${configurationCodes}`)
    .then((response) => {
      const variantPrices = response.data;
      if (!variantPrices) {
        console.log('No data found: ', variantPrices);
        return;
      }
      saveAsOrdersTable(dbAuthor, variantPrices);
    })
    .catch((error) => {
      console.log(error);
    });
}
