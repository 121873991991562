/* eslint-disable eqeqeq */
import React from 'react';
import { RGChipScroller } from 'react-rg-designsystem';

// import useSelectedVariant from '../hooks/useSelectedVariant';

import VariantText from './VariantText';
import VariantButton from './VariantButton';

import '../style/VariantList.scss';

export default function VariantList({
  option,
  configuratorId,
  optionEditorLink,
  selectionMotor,
  mode,
  isMobile,
  forceShowIncluded,
}) {
  const { selection, selectVariant } = selectionMotor(option);
  const selectedVariant = selection ? selection.selectedVariant : null;

  // Validate variants
  if (option.variants.length === 0) {
    return null;
  }

  // Debug - find option
  // if (option.id === 131) {
  //   console.log(option);
  // }

  // Sort variants
  const sortedVariants = option.variants.sort((a, b) => a.order - b.order);

  // Only show included labels if any of the variants has a price
  const showIncludedLabel = forceShowIncluded || sortedVariants.some((variant) => variant.price);

  // Build descriptions
  const descriptions = [];
  if (mode === 'VIEW') {
    // Add the selected variant
    descriptions.push(
      <VariantText
        key="selected variant"
        mode={mode}
        showIncludedLabel={showIncludedLabel}
        variant={option.variants.find((variant) => (
          variant.id == selectedVariant))}
      />,
    );
  } else if (option.variants.length > 0) {
    // Add all variants for Editor
    for (let i = 0; i < sortedVariants.length; i += 1) {
      descriptions.push(
        <VariantText
          key={sortedVariants[i].id}
          mode={mode}
          showIncludedLabel={showIncludedLabel}
          variant={sortedVariants[i]}
        />,
      );
    }
  } else {
    // Variants are missing, show Editor link
    descriptions.push(
      <a
        key="no variants"
        href={optionEditorLink}
        target="_blank"
        rel="noreferrer"
      >
        <span>Noch keine Varianten vorhanden.</span>
      </a>,
    );
  }

  // Build thumbs
  const thumbs = sortedVariants
    .map((item) => (
      <VariantButton
        key={item.id}
        configuratorId={configuratorId}
        variant={item}
        isSelected={selectedVariant == item.id}
        onClick={() => selectVariant(item.id)}
      />
    ));

  // Render
  return (
    <div
      className="VariantList"
    >
      {
        isMobile
          ? (
            <>
              <div className="descriptions">
                {descriptions}
              </div>
              <RGChipScroller
                className="thumbs mobile"
              >
                {thumbs}
              </RGChipScroller>
            </>
          ) : (
            <>
              <div className="thumbs desktop">
                {thumbs}
              </div>
              <div className="descriptions">
                {descriptions}
              </div>

            </>
          )
      }
    </div>
  );
}
