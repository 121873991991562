/* eslint-disable no-unused-vars */
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { dispatchSaveConfiguration } from '../data/actions/dispatchConfiguration';

export default function useSave(ensureSave) {
  const dispatch = useDispatch();
  const { dbAuthor } = useParams();
  const [searchParams] = useSearchParams();
  const activeConfiguration = useSelector((state) => state.activeConfiguration);
  const codeBeforeSave = useRef(undefined);

  const isSaved = () => (
    activeConfiguration.isSuccess
    && activeConfiguration.data.code !== 'new'
    && searchParams.get('code') === activeConfiguration.data.code
  );

  const savingCompleted = () => (
    codeBeforeSave.current !== searchParams.get('code')
  );

  const save = (mail) => {
    // Still saving?
    if (!savingCompleted()) {
      return;
    }

    console.log('Saving configuration');
    const savedConfiguration = activeConfiguration.data;

    // Gather UrlParameters without the code attribute
    const urlParameters = {};
    searchParams.forEach((value, key) => {
      if (key !== 'code') {
        urlParameters[key] = value;
      }
    });
    // Convert to string
    savedConfiguration.urlParameters = JSON.stringify(urlParameters);

    // Add mail to configuration
    if (mail) {
      savedConfiguration.contact = {
        ...savedConfiguration.contact,
        mail,
      };
    }

    // Save configuration
    codeBeforeSave.current = searchParams.get('code');
    dispatch(dispatchSaveConfiguration(
      dbAuthor,
      savedConfiguration,
    ));
  };

  // useEffect(() => {
  //   if (
  //     ensureSave
  //     && !activeConfiguration.isFetching
  //     && !isSaved()
  //   ) {
  //     console.log(activeConfiguration);
  //     save();
  //   }
  // }, [activeConfiguration.data]);

  return {
    isSaved,
    save,
    activeConfiguration,
  };
}
