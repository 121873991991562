import React from 'react';

import SceneView from './SceneView';

import mobileFrame from '../theme/Preview-Mobile-FullSize.png';

import '../style/MobilePreviews.scss';

function MobilePreview({ scene }) {
  return (
    <div
      className="MobilePreview"
    >
      <img
        className="mobileFrame"
        src={mobileFrame}
        alt="Mobile Frame"
      />
      <div
        className="header"
      >
        <h3>{scene.title}</h3>
      </div>
      <SceneView
        scene={scene}
      />
    </div>
  );
}

export default function MobilePreviews({ scenes }) {
  if (!scenes) {
    return null;
  }

  return (
    <div
      className="MobilePreviews"
    >
      <div
        className="wrapper"
      >
        {
          scenes.map((scene) => (
          // [scenes[0]].map((scene) => (
            <MobilePreview
              key={scene.id}
              scene={scene}
            />
          ))
        }
      </div>
    </div>
  );
}
