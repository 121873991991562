import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

import '../../style/components/FormLayout.scss';

export interface RGFormSectionProps {
  icon: IconDefinition;
  label: string;
}

export default function RGFormSection(props: RGFormSectionProps) {
  return (
    <div
      className="RGFormSection"
    >
      {
        props.icon
        ? (
          <FontAwesomeIcon
            icon={props.icon}
            size="2x"
            className="icon onPrimary"
          />
        ) : null
      }
      <h3>{props.label}</h3>
    </div>
  );
}
