import React from 'react';

import RGBrandLogo from './BrandLogo';

import '../../style/components/StartScreen.scss';
import useBrandLogo from '../hooks/useBrandLogo';
import RGFullSize from './FullSize';

export interface RGStartScreenProps {
  className?: string;
  label: string;
  children?: React.ReactNode;
  isMobile: boolean;
  logoSrc?: string,
  logoAlt?: string,
  imgSrc?: string,
  imgAlt?: string,
}

export default function RGStartScreen(props: RGStartScreenProps) {
  const brandLogo = useBrandLogo(props.logoSrc, props.logoAlt);

  return (
    <RGFullSize
      className={`RGStartScreen ${props.isMobile ? 'mobile' : 'desktop'} ${props.className || ''}`}
    >
      <div
        className="panel"
      >
        <img
          className="brandLogo"
          src={brandLogo.src}
          alt={brandLogo.alt}
          draggable="false"
        />
        <h1>{ props.label }</h1>
        <div className="content">
          { props.children }
        </div>
      </div>
      <div
        className="background"
      >
        <img
          src={props.imgSrc}
          alt={props.imgAlt}
        />
      </div>
    </RGFullSize>
  );
}
