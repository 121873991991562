import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

import {
  RGButton,
  RGButtonEnums,
  RGStartScreen,
} from 'react-rg-designsystem';

import useLocalization from '../hooks/useLocalization';
import useAnalyticsClickHandler from '../hooks/useAnalytics';
import paths, { getConfiguratorPathLive } from '../data/paths';
import analyticsEvents from '../data/analytics';

import OpenCodeScreen from '../components/OpenCodeScreen';

import moodImage from '../theme/Konfi-Mood-01.png';
import '../style/Home.scss';

export default function Home() {
  const translate = useLocalization();
  const handleConfirmOpenCode = useAnalyticsClickHandler(analyticsEvents.home.loadCode);

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [popupOpenLoadConfig, setPopupOpenLoadConfig] = useState(false);

  useEffect(() => {
    const code = searchParams.get('code');
    if (code) {
      axios
        .get(`${paths.API_CONFIGURATOR}/configuration/existing/unknown/unknown/${code}`)
        .then((response) => {
          if (response.data && response.data.configurator) {
            const author = response.data.configurator.createdBy.email.split(/@/)[0];
            const configurator = response.data.configurator.id;
            window.open(getConfiguratorPathLive(author, configurator), '_self');
          }
        });
    }
  }, [searchParams]);

  // Event handlers
  const handleOpenCode = () => {
    setPopupOpenLoadConfig(true);
  };

  const handleOpenReference = () => {
    window.open(paths.DEMO_REFERENCE, '_blank', 'noopener', 'noreferrer');
  };

  const handleCloseCodePopup = () => {
    setPopupOpenLoadConfig(false);
  };

  return (
    <RGStartScreen
      className="Home"
      label={translate('onboarding.home.title')}
      imgSrc={moodImage}
      imgAlt="Mood image"
    >
      <span>
        { translate('onboarding.description') }
      </span>
      <div className="cta">
        <RGButton
          label={translate('onboarding.openCode.button.explicit')}
          onClick={handleOpenCode}
          emphasis={RGButtonEnums.emphasis.high}
          size={RGButtonEnums.size.large}
        />
        <RGButton
          label={translate('onboarding.openReference.button')}
          onClick={handleOpenReference}
          emphasis={RGButtonEnums.emphasis.default}
          size={RGButtonEnums.size.large}
        />
      </div>
      {
        popupOpenLoadConfig && (
          <OpenCodeScreen
            onClose={handleCloseCodePopup}
            onConfirm={handleConfirmOpenCode}
          />
        )
      }
    </RGStartScreen>
  );
}
