import React from 'react';
import '../../style/components/Filter.scss';

export default function RGFilter({
  label,
  value,
}: any) {
  const items = [];
  if (value) {
    // Has Value
    items.push(
      <h6
        className='label'
        key='label'
      >
        {label}
      </h6>,
    );
    items.push(
      <h4
        className='value'
        key='value'
      >
        {value}
      </h4>,
    );
  } else {
    // Empty Value
    items.push(
      <h4
        className='label'
        key='label'
      >
        {label}
      </h4>,
    );
  }
  return (
    <div
      className="RGFilter"
    >
      { items }
    </div>
  );
}
