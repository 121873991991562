import React from 'react';

import '../style/ConfiguratedScenes.scss';
import SceneView from './SceneView';

export default function ConfiguratedScenes({ activeConfigurator }) {
  const primaryScene = activeConfigurator.scenes
    .filter((scene) => scene.is_main_scene)[0]
    || activeConfigurator.scenes[0];

  return (
    <div
      className="ConfiguratedScenes"
    >
      <SceneView
        scene={primaryScene}
      />
    </div>
  );
}
