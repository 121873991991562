import { useEffect, useState } from 'react';

export default function useBrandLogo(
  src?: string,
  alt?: string,
) {
  const brandLogo = require('../../theme/tend_logo_black.svg').default as string;
  const fallbackAlt = 'brand logo';
  const [logo, setLogo] = useState({
    src: brandLogo,
    alt: fallbackAlt,
  });
  useEffect(() => {
    if (src) {
      setLogo({
        src: src,
        alt: alt || fallbackAlt,
      });
    }
  }, [src, alt]);

  return logo;
}
