// This can be used to safely send events to our analytics provider
// This should used for more custom situations where you don't simply track buttons,
// or want to track data
export function sendAnalyticsEvent(event, data) {
  if (window.plausible) {
    window.plausible(event, data);
  }
}

// This is a handler wrapper that can forward onClick events
// If possible, it tracks the event with our analytics provider
export default function useAnalyticsClickHandler(analyticsEvent, onTrigger, data) {
  const clickHandler = (clickEvent) => {
    sendAnalyticsEvent(analyticsEvent, data);

    // Forward event
    if (onTrigger) {
      onTrigger(clickEvent);
    }
  };
  return clickHandler;
}
