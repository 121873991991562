import React, { useRef } from 'react';

import useThumbnail from '../hooks/useThumbnail';

import placeholder from '../theme/Placeholder-Image-OnElevation.png';
import '../style/VariantButton.scss';

export default function VariantButton({
  variant,
  onClick,
  isSelected = false,
}) {
  const imageRef = useRef(null);

  // Validate variant
  if (!variant) {
    return null;
  }

  // Has thumbnail?
  const { thumbnail, dbAuthor } = useThumbnail(variant);
  const hasThumb = thumbnail !== undefined;

  // Load thumb
  let thumbSrc = placeholder;
  let thumbAlt = 'placeholder';
  if (hasThumb) {
    thumbSrc = `/author_data/${dbAuthor}/thumbnails/${thumbnail.path}`;
    thumbAlt = thumbnail.title;
  }

  // Render
  return (
    <button
      className={`VariantButton ${isSelected ? 'selected' : ''}`}
      type="button"
      onClick={onClick}
    >
      <div className="border">
        <img
          ref={imageRef}
          src={process.env.PUBLIC_URL + thumbSrc}
          alt={thumbAlt}
          onError={() => {
            // console.log(`Thumbnail failed to load: ${thumbSrc}`);
            imageRef.current.onerror = null;
            imageRef.current.src = placeholder;
            imageRef.current.alt = 'placeholder';
            imageRef.current.className = 'placeholderImage';
          }}
        />
      </div>
    </button>
  );
}
