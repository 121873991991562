import React, { useRef } from 'react';

import '../style/PixelStreamingView.scss';
import usePixelStreaming from '../hooks/usePixelstreaming';

export default function PixelStreamingView({ configuratorId, activeScene }) {
  const ref = useRef(null);
  usePixelStreaming(ref, configuratorId, activeScene);

  // useEffect(() => {
  //   setInterval(() => {
  //     if (ref.current && ref.current.contentWindow) {
  //       const optionChange = {
  //         optionTag: 'T',
  //         variantTag: 1,
  //       };
  //       const command = {
  //         cmd: 'sendToUe4',
  //         value: optionChange,
  //       };
  //       // ref.current.contentWindow.emitUIInteraction({
  //       //   optionTag: 'T',
  //       //   variantTag: 1,
  //       // });
  //       ref.current.contentWindow.postMessage(JSON.stringify(command), '*');
  //       console.log('postMessage', command);
  //     }
  //   }, 5000);
  // }, []);

  return (
    <iframe
      ref={ref}
      className="PixelStreamingView"
      title="Pixel Streaming View"
      id="iframe_1"
      src="https://connector.eagle3dstreaming.com/v5/Raumgleiter/HIAG_4753_Konf/Configurator"
      // src="https://connector.eagle3dstreaming.com/v5/Raumgleiter/Devlab_5_1/Configurator"
      style={{
        visibility: 'visible',
        border: 'none',
      }}
    />
  );
}
