import React, { useEffect, useState } from 'react';
import useFullHeight from '../hooks/useFullHeight';

export interface RGFullSizeProps {
  children: any[];
  className?: string;
}

export default function RGFullSize(props: RGFullSizeProps) {
  // Track size of window
  const fullHeight = useFullHeight();

  // Render
  return (
    <div
      className={`RGFullSize ${props.className || ''}`}
      style={{ height: fullHeight }}
    >
      { props.children }
    </div>
  );
}
