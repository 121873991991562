import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import useLocalization from '../hooks/useLocalization';
import useActiveConfigs from '../hooks/useActiveConfigs';
import useVariantDependencies from '../hooks/useVariantDependencies';

import SceneListMobile from './SceneListMobile';
import SceneListDesktop from './SceneListDesktop';

import SceneListEditor from './SceneListEditor';

export default function SceneList({
  configuratorId,
  configurator,
  mode,
  isMobile,
}) {
  const translate = useLocalization();
  const { activeConfigurator, activeConfiguration } = useActiveConfigs(configuratorId);
  const [cleanedScenes, setCleanedScenes] = useState([]);
  const [searchParams] = useSearchParams();
  const { filterScenes } = useVariantDependencies(
    activeConfigurator,
    activeConfiguration,
    searchParams.get('log') === '1', // Log in Editor
  );

  // Validate Input
  if (!configurator || !configurator.scenes || configurator.scenes.length === 0) {
    return <p style={{ color: 'white' }}>{ translate('noScenes') }</p>;
  }

  // Sort and filter scenes
  useEffect(() => {
    if (configurator) {
      setCleanedScenes(
        filterScenes(
          configurator.scenes.sort((a, b) => a.order - b.order),
        ),
      );
    }
  }, [configurator]);

  // Render depending on the layout
  if (isMobile) {
    return (
      <SceneListMobile
        configuratorId={configurator.id}
        scenes={cleanedScenes}
        mode={mode}
        isMobile={isMobile}
      />
    );
  }
  if (mode === 'EDIT') {
    return (
      <SceneListEditor
        configuratorId={configurator.id}
        scenes={cleanedScenes}
        mode={mode}
        isMobile={isMobile}
      />
    );
  }
  return (
    <SceneListDesktop
      configuratorId={configurator.id}
      scenes={cleanedScenes}
      mode={mode}
      isMobile={isMobile}
    />
  );
}
